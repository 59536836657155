import TranslationService from "../../../Ceres/resources/js/src/app/services/TranslationService";

Vue.component('my-account-savedcart', {
    delimiters: ["${", "}"],
    props: ['template','hashvalue','userid','current'],
    data: function()
    {
        return {
            items: [],
            user:[],
            deleteItemsInBasket: false,
            waiting:false,
            session:[],
            configs:[],
            loading:false
        };
    },
    mounted: function()
    {
        this.giftItems();
        this.getConfigs();
        this.getSession();
    },
    methods:
    {
        copyCodeServerCode:function(code)
        {
            navigator.clipboard.writeText(code).then(() =>
            {
                CeresNotification.success(TranslationService.translate('YbbaCartSave::Template.configToZwischenablage')).closeAfter(3000);
            }).catch(err =>
            {
                CeresNotification.error(TranslationService.translate('Fehler beim kopieren '+err)).closeAfter(3000);
            });
        },
        deleteConfig:function(id)
        {
            let _self = this;
            $.ajax({
                type: 'GET',
                url: '/cartsave/config/delete/'+id,
                success: function ()
                {
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.configDeletet")).closeAfter(3000);
                    _self.getConfigs();
                },
                error: function (jqXHR, textStatus, errorThrown)
                {
                    CeresNotification.error('jqXHR'+jqXHR+'textStatus'+textStatus+'errorTrown:'+errorThrown);
                }
            })
        },
        buyConfig:function(hash,itemId)
        {
            this.loading = true;
            let dataObj =
            {
                "hash":hash,
                "itemId":itemId
            }
            let _self = this;
            $.ajax({
                type:           'POST',
                data:           dataObj,
                url:            '/cartsave/configs/addtobasket/',
                dataType:       'json',
                success:        function(data)
                {
                    vueApp.$store.commit("setBasketItems", data.basketItems);
                    vueApp.$store.commit("setBasket", data.basket);
                    _self.loading = false;
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.configAdded")).closeAfter(3000);
                },
                error:          function(jqXHR, textStatus, errorThrown)
                {
                    console.error(errorThrown);
                    _self.loading = false;
                }
            });
        },
        getConfigs:function()
        {
            if(this.userid)
            {
                let _self = this;
                $.ajax({
                    type:           'GET',
                    url:            '/cartsave/configs/get/'+this.userid,
                    dataType:       'json',
                    success:        function(data)
                    {
                      _self.configs = data;
                    },
                    error:          function(jqXHR, textStatus, errorThrown)
                    {
                        console.error(errorThrown);
                    }
                });
            }

        },
        buySet:function(currentVariation)
        {
            let setitem = {
                "variationId"   : currentVariation.variation.id,
                "quantity"        : 1,
                "setComponents"   : [],
                "template"        : "Ceres::Basket.Basket",
                "templateType"    : "item"
            };
            for(let i = 0; i < currentVariation.setComponents.length; i++)
            {
                setitem.setComponents.push
                (
                    {
                        "variationId"   :   currentVariation.setComponents[i].defaultVariationId,
                        "quantity"      :   currentVariation.setComponents[i].minimumOrderQuantity,
                        "totalOrderParamsMarkup" : 0
                    }
                )
            }
        },
        getSession: function()
        {
            let _this = this;
            $.ajax(
        {
                type: 'GET',
                url: '/cartsave/cart/setsession/get/',
                dataType: 'json',
                success: function (data)
                {
                    _this.session = data;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error(errorThrown);
                }
            });
        },
        giftItems: function()
        {
            var _self = this;

            if(this.hashvalue)
            {
                return $.ajax({
                    type:           'GET',
                    url:            '/cartsave/user/public/'+this.hashvalue,
                    dataType:       'json',
                    success:        function(data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {

                            data[i].cartURL = document.location.origin+'/cartlist/'+data[i].cartURL+'/';

                            if(data[i].cartPublic==="0")
                            {
                                data[i].cartPublic = false;
                            }
                            else
                            {
                                data[i].cartPublic = true;
                            }
                            if(data[i].weddingMode==="0")
                            {
                                data[i].weddingMode = false;
                            }
                            else
                            {
                                data[i].weddingMode = true;
                            }
                            data[i].clonedItems = [];
                        }
                        _self.items = data;

                    },
                    error:          function(jqXHR, textStatus, errorThrown)
                    {
                        console.error(errorThrown);
                    }
                });
            }
            else
            {
                return $.ajax({
                    type:           'GET',
                    url:            '/cartsave/user/'+this.userid,
                    dataType:       'json',
                    success:        function(data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {
                            data[i].cartURL = document.location.origin+'/cartlist/'+data[i].cartURL+'/';
                            if(data[i].cartPublic==="0")
                            {
                                data[i].cartPublic = false;
                            }
                            else
                            {
                                data[i].cartPublic = true;
                            }
                            if(data[i].weddingMode==="0")
                            {
                                data[i].weddingMode = false;
                            }
                            else
                            {
                                data[i].weddingMode = true;
                            }
                            data[i].searchedItems = [];
                            data[i].selectedSearchItem = [];
                            if(data[i].hasOwnProperty('items'))
                            {
                                for (var e = 0; e < data[i].items.length; e++) {
                                    data[i].items[e].allVars = [];
                                }
                            }
                            data[i].clonedItems = [];
                        }
                        _self.items = data;
                    },
                    error:          function(jqXHR, textStatus, errorThrown)
                    {
                        console.error(errorThrown);
                    }
                });
            }
        },
        cartDelete: function(id)
        {
            var _self = this;
            for( var i = 0; i < this.items.length; i++)
            {
                if ( this.items[i].cartId === id)
                {
                    this.items.splice(i, 1);
                }
            }
            $.ajax({
                type: 'POST',
                url: '/cartsave/delete/',
                data: {"id": id},
                success: function (data) {
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbgeloescht")).closeAfter(3000);

                },
                error: function (jqXHR, textStatus, errorThrown) {
                    CeresNotification.error('jqXHR'+jqXHR+'textStatus'+textStatus+'errorTrown:'+errorThrown);
                }
            })
        },
        savedCartItemToBasket: function(item,wedding = 0,carts = 0)
        {
            let _self = this;
            _self.waiting = true;
            var variations = [];
            variations.push(
            {
                "variationId": item.id,
                "quantity":item.quantity,
                "name":item.data.texts.shortDescription
            });
            var tosubmit = {
                "items": variations
            }
            $.ajax
            ({
                    type: 'POST',
                    url: '/cartsave/addtobasket/',
                    data: tosubmit,
                    success: function (data) {
                        for (var i = 0; i < data.length; i++)
                        {
                            if (data[i].error)
                            {
                                CeresNotification.error(TranslationService.translate("YbbaCartSave::Template.notesNichtgenugbestand",
                                    {
                                        variationName: data[i].error.variationName,
                                        stock: data[i].error.placeholder.stock
                                    })
                                );
                            } else {
                                CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesArtikelhinzugefuegt")).closeAfter(3000);

                                if(wedding === 1)
                                {
                                    if(_self.session.length > 0)
                                    {
                                        for(var c = 0; c < _self.session.length; c++)
                                        {
                                            if(parseFloat(_self.session[c].cart) == parseFloat(carts.cartId))
                                            {
                                                // ist aus der selben cart
                                                var indexFound = 0;
                                                for(var t = 0; t < _self.session[c].items.length; t++)
                                                {

                                                    if(parseFloat(_self.session[c].items[t].variationId) ===  parseFloat(tosubmit.items[0].variationId))
                                                    {
                                                       indexFound = t;
                                                    }
                                                }
                                                if(indexFound === 0)
                                                {
                                                    // füge der liste hinzu
                                                    _self.session[c].items.push
                                                    (
                                                        tosubmit.items[0]
                                                    )
                                                }
                                                else
                                                {
                                                    // ändere menge
                                                    _self.session[c].items[indexFound].quantity =  + parseFloat(_self.session[c].items[indexFound].quantity)+parseFloat(tosubmit.items[0].quantity);
                                                    var sessionData =
                                                    {
                                                        "session":_self.session
                                                    }
                                                    $.ajax
                                                    ({
                                                        type: 'POST',
                                                        url: '/cartsave/cart/setsession/',
                                                        data: sessionData,
                                                        success: function (data)
                                                        {
                                                            _self.session = data;
                                                        }
                                                    });
                                                }

                                            }
                                            else
                                            {
                                                _self.session.push(
                                                    {
                                                        "items":tosubmit.items,
                                                        "cart":parseFloat(carts.cartId)
                                                    }
                                                )

                                                $.ajax
                                                ({
                                                    type: 'POST',
                                                    url: '/cartsave/cart/setsession/',
                                                    data: {"session":_self.session},
                                                    success: function (data)
                                                    {
                                                        _self.session = data;
                                                    }
                                                });
                                            }

                                        }
                                    }
                                    else
                                    {
                                        let dataForWedding =
                                        {
                                            "session":
                                            [
                                                {
                                                    "items":tosubmit.items,
                                                    "cart":carts.cartId
                                                }
                                            ]
                                        }
                                        $.ajax
                                        ({
                                            type: 'POST',
                                            url: '/cartsave/cart/setsession/',
                                            data: dataForWedding,
                                            success: function (data)
                                            {
                                                _self.session = data;
                                            }
                                        });
                                    }
                                }
                            }
                            if (i === data.length - 1) {
                                vueApp.$store.commit("setBasketItems", data[i].basketItems);
                                vueApp.$store.commit("setBasket", data[i].basket);
                            }
                        }
                        _self.waiting = false;
                    },
                    error: function (jqXHR) {
                        console.log(jqXHR);
                    }
                });

        },
        addItemToList:function(cart,item)
        {

            let _self = this;

            var itemsplit = item.url.split('a-');

            var rev       = itemsplit.reverse();
            var itemId    = parseInt(rev[0]);
            if(Number.isInteger(itemId)==false)
            {
                var itemsplit2 = item.url.split('_');
                itemId = itemsplit2[1];
            }
            return $.ajax({
                type:           'GET',
                url:            '/cartsave/cart/getitem/id/'+itemId,
                dataType:       'json',
                success:        function(data)
                {
                    cart.selectedSearchItem = [{}];
                    cart.selectedSearchItem[0].attrItems = [];
                    for(let i = 0; i < data.documents.length; i++ )
                    {
                        if(i==0)
                        {
                            if(data.documents[i].data.attributes.length > 0)
                            {
                                cart.selectedSearchItem[0].attrName = data.documents[0].data.attributes[0].attribute.names.name;
                            }
                            cart.selectedSearchItem[0].data = data.documents[0].data;
                        }
                        if(data.documents[i].data.attributes.length > 0)
                        {
                            cart.selectedSearchItem[0].attrItems.push(
                            {
                                "selected": false,
                                "attrvalue": data.documents[i].data.attributes[0].value.names.name,
                                "variationid": data.documents[i].data.variation.id,
                                "data": data.documents[i].data
                            });
                        }

                    }
                    cart.searchedItems = [];
                },
                error:          function(jqXHR, textStatus, errorThrown)
                {
                    console.error(errorThrown);
                }
            });

        },
        searchItems:function(cart,index,event)
        {
            $.ajax({
                type:           'GET',
                url:            '/rest/io/item/search/autocomplete/?query='+event.target.value+'&types%5B%5D=item&types%5B%5D=suggestion',
                dataType:       'json',
                success:        function(response)
                {
                   cart.searchedItems = response.data.item
                },
                error:          function(jqXHR, textStatus, errorThrown)
                {
                    console.error(errorThrown);
                }
            });
        },
        listToBasket: function(cart)
        {
            let _self = this;
            _self.waiting = true;
            if(_self.deleteItemsInBasket === true)
            {
                _self.deleteItemsInBasket = false;
                _self.listToBasketDeleteOld(cart)
            }
            else
            {
                var variations = [];
                for( var i = 0; i < cart.length; i++)
                {
                    variations.push(
                        {
                            "variationId": cart[i].id,
                            "quantity":cart[i].quantity,
                            "name":cart[i].data.texts.name1
                        });
                }
                var data = {
                    "items": variations
                }
                $.ajax({
                    type: 'POST',
                    url: '/cartsave/addtobasket/',
                    data: data,
                    success: function (data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {
                            if(data[i].error)
                            {
                                CeresNotification.error(TranslationService.translate("YbbaCartSave::Template.notesNichtgenugbestand",
                                    {
                                        variationName: data[i].error.variationName,
                                        stock: data[i].error.placeholder.stock
                                    })
                                );
                            }
                            else
                            {
                                CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesArtikelhinzugefuegt")).closeAfter(3000);
                            }
                            if(i===data.length-1)
                            {
                                vueApp.$store.commit("setBasketItems", data[i].basketItems);
                                vueApp.$store.commit("setBasket", data[i].basket);
                            }
                        }
                        _self.waiting = false;
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log(jqXHR);
                    }
                })
            }
        },
        deleteItemFromSavedCart: function (cart,index)
        {
            cart.items.splice(index,1);
            var data = {
                "id":cart.cartId,
                "customerId":this.userid,
                "cartName":cart.cartName,
                "variations":cart.items
            }
            $.ajax(
            {
                type: 'POST',
                url: '/cartsave/update/',
                data: data,
                success: function (response)
                {
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbUpdate",{ cartName: cart.cartName })).closeAfter(3000);
                },
                error: function (jqXHR)
                {
                    console.log(jqXHR);
                }
            })

        },
        deletePublic: function (cart,index)
        {
            cart.items.splice(index,1);
        },
        activatWeddingMode: function(cart)
        {
            let _self = this;
            if(cart.weddingMode == true)
            {
                cart.weddingMode = false;
            }
            else
            {
                cart.weddingMode = true;
            }
            _self.updateCart(cart);
        },
        updateCart: function (cart)
        {
            let _self = this;
            var data = {
                "id":cart.cartId,
                "customerId":this.userid,
                "cartName":cart.cartName,
                "cartPublic": cart.cartPublic,
                "variations":cart.items,
                "cartUpdateAt":Date.now() / 1000,
                "cartWeddingMode":cart.weddingMode
            }
            _self.waiting = true;
            $.ajax(
                {
                    type: 'POST',
                    url: '/cartsave/update/',
                    data: data,
                    success: function (response)
                    {
                        CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbUpdate",{ cartName: cart.cartName })).closeAfter(3000);
                        _self.waiting = false;
                    },
                    error: function (jqXHR)
                    {
                        _self.waiting = false;
                        console.log(jqXHR);
                    }
                })

        },
        listToBasketDeleteOld: function(items)
        {
            var _self = this;
            $.ajax(
                {
                    type: 'POST',
                    url: '/cartsave/basket/delete/',
                    success: function (response)
                    {
                        _self.listToBasket(items)
                    },
                    error: function (jqXHR)
                    {
                        console.log(jqXHR);
                    }
                });
        },
        selectVariation: function(cart,attrvariation,event)
        {
            for(let i = 0 ; i < cart.selectedSearchItem[0].attrItems.length; i++)
            {
                cart.selectedSearchItem[0].attrItems[i].selected = false;
                if(cart.selectedSearchItem[0].attrItems[i].variationid == attrvariation.variationid)
                {
                    cart.selectedSearchItem[0].attrItems[i].selected = true;
                }
            }
            $(event.target).parent('.v-s-boxes').find('.v-s-box').removeClass('active');
            $(event.target).addClass('active');
        },
        resetSearch: function(cart)
        {
            cart.selectedSearchItem = [];
        },
        addVariationToList: function (cart)
        {
            let _self = this;
            let findOneToAdd = {};

            if(cart.selectedSearchItem[0].attrItems.length > 0)
            {
                for(let i = 0; i < cart.selectedSearchItem[0].attrItems.length; i++ )
                {
                    if(cart.selectedSearchItem[0].attrItems[i].selected == true)
                    {
                        findOneToAdd =
                        {
                            "data" : cart.selectedSearchItem[0].attrItems[i].data,
                            "id": cart.selectedSearchItem[0].attrItems[i].variationid,
                            "quantity": 1,
                            "allVars": []
                         };

                    }
                }
            }
            else
            {
                findOneToAdd =
                {
                    "data" : cart.selectedSearchItem[0].data,
                    "id": cart.selectedSearchItem[0].data.variation.id,
                    "quantity": 1,
                    "allVars": []
                };
            }

            cart.items.push(findOneToAdd);
            _self.updateCart(cart)
        },
        changeCurrentVariation: function(carts,item,attr,event)
        {

            let _self = this;
            let changedItem = "";
            for(let i = 0; i < item.allVars[0].attrItems.length; i++)
            {
                if(item.allVars[0].attrItems[i].variationid == attr.variationid)
                {
                    changedItem = item.allVars[0].attrItems[i]
                }
            }

           for( let c = 0; c < carts.clonedItems.length; c++)
           {
               if(carts.clonedItems[c].id == item.id)
               {
                   carts.clonedItems[c].id = changedItem.variationid;
                   carts.clonedItems[c].data = changedItem.data;
               }
           }

            $(event.target).parent('.v-s-boxes').find('.v-s-box').removeClass('active');
            $(event.target).addClass('active');

        },
        changeVariationSave: function(carts)
        {
            let _self = this;
            _self.updateCart(carts);
            for( let i = 0; i < carts.items.length; i++)
            {
                carts.items[i].allVars = [];
            }
        },
        changeVariation: function (variation,carts)
        {
            let _self = this;
            _self.waiting = true;
            return $.ajax({
                type:           'GET',
                url:            '/cartsave/cart/getitem/id/'+variation.data.item.id,
                dataType:       'json',
                success:        function(data)
                {
                    variation.allVars = [{}];
                    variation.allVars[0].attrItems = [];
                    for(let i = 0; i < data.documents.length; i++ )
                    {
                        if(i==0)
                        {
                            if(data.documents[i].data.attributes.length > 0)
                            {
                                variation.allVars[0].attrName = data.documents[0].data.attributes[0].attribute.names.name;
                            }
                            variation.allVars[0].data = data.documents[0].data;
                        }
                        if(data.documents[i].data.attributes.length > 0)
                        {
                            variation.allVars[0].attrItems.push(
                                {
                                    "selected": false,
                                    "attrvalue": data.documents[i].data.attributes[0].value.names.name,
                                    "variationid": data.documents[i].data.variation.id,
                                    "data": data.documents[i].data
                                });
                        }

                    }
                    carts.clonedItems = carts.items;
                    _self.waiting = false;
                },
                error:          function(jqXHR, textStatus, errorThrown)
                {
                    console.error(errorThrown);
                }
            });
        }
    },
    computed:
        {
            allGiftItems: function()
            {
                var _self = this;
                return _self.items;
            }
        }
});